import validator from "validator";
import styles from "./AdminLogin.module.css";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import InputField from "./InputField/InputField";
import { userLogin } from "../../env/APIManager";
import ClipLoader from "react-spinners/ClipLoader";
import { toast, ToastContainer } from "react-toastify";
import { motion } from "framer-motion/dist/framer-motion";
import "react-toastify/dist/ReactToastify.css";

export default function Login() {
	const [userEmail, setUserEmail] = useState("");
	const [userPassword, setUserPassword] = useState("");
	const [errors, setErrors] = useState({ userEmail: "", userPassword: "" });
	const [loading, setLoading] = useState(false);
	const { push } = useHistory();

	const validate = () => {
		const errorMessages = {};
		errorMessages.userEmail = userEmail
			? validator.isEmail(userEmail.trim())
				? ""
				: "Email is not valid. "
			: "";
		errorMessages.userPassword = userPassword
			? userPassword.trim().length > 5
				? ""
				: "Password must be at least 6 charaxters long. "
			: "";
		setErrors(errorMessages);
	};

	const handleLogin = async (event) => {
		setLoading(true);
		if (errors?.userEmail || errors?.userPassword) {
			if (errors.userPassword.length > 0 || errors.userEmail.length > 0) {
				let toastMessages = [];
				toastMessages.push(errors.userEmail ? errors.userEmail : "");
				toastMessages.push(
					errors.userPassword ? ` ${errors.userPassword}` : ""
				);
				toastMessages.forEach((toastMsg) => {
					if (toastMsg) toast.error(toastMsg);
				});
				setLoading(false);
			} else {
				const res = await userLogin(userEmail, userPassword);
				if (res?.code === "SUCCESS") {
					localStorage.setItem(
						"token",
						JSON.stringify(res?.data?.user?.tokenId)
					);
					push({ pathname: `/map` });
				} else {
					toast.error("There was an error logging you into the app.");
				}
				setLoading(false);
			}
		} else {
			if (userEmail && userPassword) {
				const res = await userLogin(userEmail, userPassword);
				if (res?.code === "SUCCESS") {
					localStorage.setItem(
						"token",
						JSON.stringify(res?.data?.user?.tokenId)
					);

					push({ pathname: `/map` });
				} else {
					toast.error("There was an error logging you into the app.");
				}
				setLoading(false);
			} else {
				setErrors({
					userEmail: userEmail ? "" : "Email is required.",
					userPassword: userPassword ? "" : "Password is required.",
				});
				let toastMessages = [];
				toastMessages.push(errors.userEmail ? errors.userEmail : "");
				toastMessages.push(
					errors.userPassword ? ` ${errors.userPassword}` : ""
				);
				toastMessages.forEach((toastMsg) => {
					if (toastMsg) toast.error(toastMsg);
				});
				setLoading(false);
			}
		}
	};

	return (
		<>
			<ToastContainer />
			{loading ? (
				<div className={styles.loaderContainer}>
					<ClipLoader color="#6C40FC" size={50} />
				</div>
			) : (
				<motion.div className={styles.container}>
					<div className={styles.loginContainer}>
						<div className={styles.innerContainer}>
							<h1 className={styles.title}>Log In</h1>
							<div className={styles.grunner_input_field}>
								<InputField
									value={userEmail}
									setValue={setUserEmail}
									field={"Email"}
									fullWidth={true}
									width={"100%"}
									validate={validate}
									errors={errors.userEmail}
									type={"email"}
								/>
							</div>
							<div className={styles.grunner_input_field}>
								<InputField
									value={userPassword}
									setValue={setUserPassword}
									field={"Password"}
									fullWidth={true}
									width={"100%"}
									validate={validate}
									errors={errors.userPassword}
									type={"password"}
								/>
							</div>
						</div>

						<button className={styles.sign_in_button} onClick={handleLogin}>
							Sign In
						</button>
					</div>
				</motion.div>
			)}
		</>
	);
}
