/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ClipLoader from "react-spinners/ClipLoader";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import styles from "./CRM_Main_Page.module.css";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArrowRightSharpIcon from "@mui/icons-material/ArrowRightSharp";

import { getCRMData } from "../env/APIManager";

import DataTable from "./DataTable";
import SearchBar from "./search-bar/SearchBar";
import Filter from "./filter/Filter";

function CRM_Main_Page() {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [nextPageToken, setNextPageToken] = useState("");
	const [prevPageToken, setPrevPageToken] = useState("");
	const [currentPageToken, setCurrentPageToken] = useState("");
	const [hasNextPage, setHasNextPage] = useState(false);
	const [hasPrevPage, setHasPrevPage] = useState(false);
	const [token, setToken] = useState("");
	const [showActionBtn, setShowActionBtn] = useState(false);
	const [colorCoded, setColorCoded] = useState(false);

	const [isOptionsOpen, setIsOptionsOpen] = useState(false);

	const [selected, setSelected] = useState("");
	const [categoryId, setCategoryId] = useState("");
	const [searchQuery, setSeacthQuery] = useState("");
	const [filterId, setFilterId] = useState("");

	let history = useHistory();

	const search = window.location.search;
	const params = new URLSearchParams(search);

	const key = params.get("key");
	const nextToken = params.get("nextPageToken");
	const savedKey = localStorage.getItem("key");
	const savedCategoryId = localStorage.getItem("categoryId");
	const savedSearchQuery = localStorage.getItem("searchQuery");
	const savedFilterId = localStorage.getItem("filterId");

	useEffect(() => {
		let token =
			!localStorage.getItem("token")
				? ""
				: JSON.parse(localStorage.getItem("token"));
		setToken(token);
		if (
			currentPageToken &&
			currentPageToken !== null &&
			currentPageToken !== undefined
		) {
			fetchDataBasedOnSelected(token, "currentPageToken");
		} else {
			fetchDataBasedOnSelected(token);
		}
		localStorage.setItem("key", selected);
		localStorage.setItem("categoryId", categoryId);
		localStorage.setItem("searchQuery", searchQuery);
		localStorage.setItem("filterId", filterId);
	}, [selected, categoryId, searchQuery,filterId]);

	useEffect(() => {
		if (nextToken) {
			setCurrentPageToken(nextToken);
		}
		if (key && key !== null && key !== undefined && key !== "null") {
			setSelected(key);
			localStorage.setItem("key", key);

			// Clear the "url" and "nextPageToken" query parameter
			// params.delete("key");
			// params.delete("nextPageToken");
			// history.replace({ search: params.toString() });

			return;
		} else if (savedKey) {
			function checkSavedKeyForKeyword(keyword) {
				return savedKey?.includes(keyword);
			}

			// Iterate through crmContent
			for (const item of crmContent) {
				const title = item?.title;

				let foundMatch = false;

				for (const child of item.children) {
					const keyword = `${title}-${child.title}`;

					if (checkSavedKeyForKeyword(keyword)) {
						foundMatch = true;
						break;
					}
				}

				if (foundMatch) {
					setSelected(savedKey);
					break;
				} else {
					setSelected("Consumer-Orders");
					localStorage.setItem("key", "Consumer-Orders");
				}
			}
		} else {
			// Default state and save to local storage
			setSelected("Consumer-Orders");
			localStorage.setItem("key", "Consumer-Orders");
		}

		if (savedCategoryId && savedSearchQuery) {
			setCategoryId(savedCategoryId);
			setSeacthQuery(savedSearchQuery);
		}
		if (savedFilterId) {
			setFilterId(savedFilterId);
		}
	}, []);

	const handleGoBack = () => {
		history.push("/map");
	};

	const toggleOptions = () => {
		setIsOptionsOpen(!isOptionsOpen);
	};

	let crmContent = [
		{
			title: "Consumer",
			children: [{ title: "Users" }, { title: "Orders" }],
		},
		{
			title: "Merchant",
			children: [{ title: "Users" }, { title: "Tasks" }],
		},
		{
			title: "Grunner",
			children: [{ title: "Users" }, { title: "Tasks" }],
		},
		{
			title: "Business",
			children: [{ title: "Users" }, { title: "Orders" }],
		},
	];

	const handleTitleClick = (title, parentTitle, hasChildren) => {
		if (hasChildren) {
			return;
		} else {
			setNextPageToken("");
			setPrevPageToken("");
			setCurrentPageToken("");
			setCategoryId("");
			setSeacthQuery("");
			setFilterId("");
			if (parentTitle?.length > 0) {
				setSelected(`${parentTitle}-${title}`);
				toggleOptions();
				return;
			} else {
				setSelected(title);
				toggleOptions();
			}
		}
	};
	function Entry({ entry, parentTitle = "" }) {
		const [isVisible, setIsVisible] = useState(false);

		return (
			<div>
				<div
					className={`${styles.entry} ${
						selected === `${parentTitle}-${entry.title}` ? styles.selected : ""
					}`}
					onClick={() => {
						setIsVisible(!isVisible);
						handleTitleClick(
							entry.title,
							parentTitle,
							entry.children && entry.children.length > 0
						);
					}}
				>
					{entry?.children && <ArrowRightSharpIcon />}
					{entry?.title}
				</div>

				<div>
					{isVisible && (
						<div className={styles.childEntries}>
							{entry?.children?.map((childEntry) => {
								return (
									<Entry
										entry={childEntry}
										parentTitle={entry.title}
										key={childEntry.title}
									/>
								);
							})}
						</div>
					)}
				</div>
			</div>
		);
	}

	async function handleAPIResponse(res) {
		if (res?.code === "SUCCESS") {
			setData(res?.data);
			setNextPageToken(res?.data?.nextPageToken);
			setPrevPageToken(res?.data?.prevPageToken);
			setHasNextPage(res?.data?.hasMore);
			setHasPrevPage(res?.data?.hasLess);
			setLoading(false);
		} else if (res?.data.code === "UNAUTHORIZED") {
			localStorage.clear();
			setLoading(false);
			toast.error(
				"You have been logged out. This may have been caused by using more than one device or browser"
			);
				history.push({ pathname: "/login" });
		} else {
			toast.error("Something went wrong, please try again later");
			setLoading(false);
			setData([]);
		}
	}

	const fetchDataBasedOnSelected = async (token, string) => {
		let url;
		let data;
		let hasQueryParams = false;
		try {
			setLoading(true);
			switch (selected) {
				case "Consumer-Users":
					if (string === "next") {
						url = `consumer/users/all?nextToken=${nextPageToken}`;
						hasQueryParams = true;
					} else if (string === "prev") {
						url = `consumer/users/all?prevToken=${prevPageToken}`;
						hasQueryParams = true;
					} else if (string === "currentPageToken") {
						url = `consumer/users/all?currentToken=${currentPageToken}`;
						hasQueryParams = true;
					} else {
						url = `consumer/users/all`;
						hasQueryParams = false;
					}
					// Add query parameters if categoryId and searchQuery are not empty
					if (categoryId !== "" && searchQuery !== "") {
						url += hasQueryParams
							? `&searchCatId=${categoryId}&searchQuery=${searchQuery}`
							: `?searchCatId=${categoryId}&searchQuery=${searchQuery}`;
					}
					if (filterId !== "" ) {
						if(categoryId !== "" && searchQuery !== ""){
							hasQueryParams = true;
						}
						url += hasQueryParams
							? `&filterId=${filterId}`
							: `?filterId=${filterId}`;
					}

					data = await getCRMData(token, url);

					await handleAPIResponse(data, selected);
					setShowActionBtn(true);
					setColorCoded(false)

					break;

				case "Consumer-Orders":
					if (string === "next") {
						url = `orders/consumer/all?nextToken=${nextPageToken}`;
						hasQueryParams = true;
					} else if (string === "prev") {
						url = `orders/consumer/all?prevToken=${prevPageToken}`;
						hasQueryParams = true;

					} else if (string === "currentPageToken") {
						url = `orders/consumer/all?currentToken=${currentPageToken}`;
						hasQueryParams = true;
					} else {
						url = `orders/consumer/all`;
						hasQueryParams = false;
					}
					if (categoryId !== "" && searchQuery !== "") {
						url += hasQueryParams
							? `&searchCatId=${categoryId}&searchQuery=${searchQuery}`
							: `?searchCatId=${categoryId}&searchQuery=${searchQuery}`;
					}

					if (filterId !== "" ) {
						if(categoryId !== "" && searchQuery !== ""){
							hasQueryParams = true;
						}
						url += hasQueryParams
							? `&filterId=${filterId}`
							: `?filterId=${filterId}`;
					}
					data = await getCRMData(token, url);

					await handleAPIResponse(data, selected);
					setShowActionBtn(true);
					setColorCoded(true);

					break;
				case "Merchant-Users":
					if (string === "next") {
						url = `merchant/users/all?nextToken=${nextPageToken}`;
					} else if (string === "prev") {
						url = `merchant/users/all?prevToken=${prevPageToken}`;
					} else if (string === "currentPageToken") {
						url = `merchant/users/all?currentToken=${currentPageToken}`;
					} else {
						url = `merchant/users/all`;
					}
					if (filterId !== "" ) {
						if(categoryId !== "" && searchQuery !== ""){
							hasQueryParams = true;
						}
						url += hasQueryParams
							? `&filterId=${filterId}`
							: `?filterId=${filterId}`;
					}


					data = await getCRMData(token, url);

					await handleAPIResponse(data, selected);
					setShowActionBtn(true);
					setColorCoded(false)


					break;
				case "Merchant-Tasks":
					if (string === "next") {
						url = `merchant/tasks/all?nextToken=${nextPageToken}`;
					} else if (string === "prev") {
						url = `merchant/tasks/all?prevToken=${prevPageToken}`;
					} else if (string === "currentPageToken") {
						url = `merchant/tasks/all?currentToken=${currentPageToken}`;
					} else {
						url = `merchant/tasks/all`;
					}

					if (filterId !== "" ) {
						if(categoryId !== "" && searchQuery !== ""){
							hasQueryParams = true;
						}
						url += hasQueryParams
							? `&filterId=${filterId}`
							: `?filterId=${filterId}`;
					}

					data = await getCRMData(token, url);

					await handleAPIResponse(data, selected);
					setShowActionBtn(true);
					setColorCoded(false)


					break;
				case "Grunner-Users":
					if (string === "next") {
						url = `grunner/users/all?nextToken=${nextPageToken}`;
					} else if (string === "prev") {
						url = `grunner/users/all?prevToken=${prevPageToken}`;
					} else if (string === "currentPageToken") {
						url = `grunner/users/all?currentToken=${currentPageToken}`;
					} else {
						url = `grunner/users/all`;
					}

					if (filterId !== "" ) {
						if(categoryId !== "" && searchQuery !== ""){
							hasQueryParams = true;
						}
						url += hasQueryParams
							? `&filterId=${filterId}`
							: `?filterId=${filterId}`;
					}

					data = await getCRMData(token, url);

					await handleAPIResponse(data, selected);
					setShowActionBtn(true);
					setColorCoded(false)


					break;
				case "Grunner-Tasks":
					if (string === "next") {
						url = `grunner/tasks/all?nextToken=${nextPageToken}`;
					} else if (string === "prev") {
						url = `grunner/tasks/all?prevToken=${prevPageToken}`;
					} else if (string === "currentPageToken") {
						url = `grunner/tasks/all?currentToken=${currentPageToken}`;
					} else {
						url = `grunner/tasks/all`;
					}
					if (filterId !== "" ) {
						if(categoryId !== "" && searchQuery !== ""){
							hasQueryParams = true;
						}
						url += hasQueryParams
							? `&filterId=${filterId}`
							: `?filterId=${filterId}`;
					}


					data = await getCRMData(token, url);

					await handleAPIResponse(data, selected);
					setShowActionBtn(true);
					setColorCoded(false)

					break;
				case "Business-Users":
					if (string === "next") {
						url = `business/users/all?nextToken=${nextPageToken}`;
					} else if (string === "prev") {
						url = `business/users/all?prevToken=${prevPageToken}`;
					} else if (string === "currentPageToken") {
						url = `business/users/all?currentToken=${currentPageToken}`;
					} else {
						url = `business/users/all`;
					}

					if (filterId !== "" ) {
						if(categoryId !== "" && searchQuery !== ""){
							hasQueryParams = true;
						}
						url += hasQueryParams
							? `&filterId=${filterId}`
							: `?filterId=${filterId}`;
					}

					data = await getCRMData(token, url);

					await handleAPIResponse(data, selected);
					setShowActionBtn(true);
					setColorCoded(false)

					break;

				case "Business-Orders":
					if (string === "next") {
						url = `orders/business/all?nextToken=${nextPageToken}`;
					} else if (string === "prev") {
						url = `orders/business/all?prevToken=${prevPageToken}`;
					} else if (string === "currentPageToken") {
						url = `orders/business/all?currentToken=${currentPageToken}`;
					} else {
						url = `orders/business/all `;
					}

					if (filterId !== "" ) {
						if(categoryId !== "" && searchQuery !== ""){
							hasQueryParams = true;
						}
						url += hasQueryParams
							? `&filterId=${filterId}`
							: `?filterId=${filterId}`;
					}

					data = await getCRMData(token, url);

					await handleAPIResponse(data, selected);
					setShowActionBtn(true);
					setColorCoded(false)

					break;
				default:
					break;
			}
		} catch (error) {
			toast.error("Something went wrong, please try again later");
			setLoading(false);
			setData([]);
		}
	};

	const handleNextPage = () => {
		fetchDataBasedOnSelected(token, "next");
	};

	const handlePreviousPage = () => {
		fetchDataBasedOnSelected(token, "prev");
	};

	const handleFirstPage = () => {
		fetchDataBasedOnSelected(token);
	};

	const handleView = (data) => {
		if (selected === "Consumer-Orders") {
			let id = data["Order ID"];
			let type = data["Type"];
			let link = "";

			if (
				prevPageToken &&
				prevPageToken !== null &&
				prevPageToken !== undefined
			) {
				// link = `/crm/order-details?id=${id}&key=${selected}&nextPageToken=${prevPageToken}&path=${history?.location?.pathname}`;
				link = `/crm/order-details?id=${id}&type=${type}`;
			} else {
				// link = `/crm/order-details?id=${id}&path=${history?.location?.pathname}`;
				link = `/crm/order-details?id=${id}&type=${type}`;
			}

			window.open(link, "_blank");
		} else if (selected === "Merchant-Tasks" || selected === "Grunner-Tasks") {
			let id = data["Order ID"];
			let type = data["App Type"];
			let link = "";
			if (
				prevPageToken &&
				prevPageToken !== null &&
				prevPageToken !== undefined
			) {
				// link = `/crm/order-details?id=${id}&key=${selected}&nextPageToken=${prevPageToken}&path=${history?.location?.pathname}`;
				link = `/crm/order-details?id=${id}&type=${type}`;
			} else {
				// link = `/crm/order-details?id=${id}&path=${history?.location?.pathname}`;
				link = `/crm/order-details?id=${id}&type=${type}`;
			}

			window.open(link, "_blank");
		} else if (selected === "Consumer-Users") {
			let id = data["User ID"];
			let link = "";

			if (
				prevPageToken &&
				prevPageToken !== null &&
				prevPageToken !== undefined
			) {
				// link = `/crm/consumer-user?id=${id}&key=${selected}&nextPageToken=${prevPageToken}`;
				link = `/crm/consumer-user?id=${id}`;
			} else {
				// link = `/crm/consumer-user?id=${id}&key=${selected}`;
				link = `/crm/consumer-user?id=${id}`;
			}

			window.open(link, "_blank");
		} else if (selected === "Business-Users") {
			let id = data["User ID"];
			let link = "";

			if (
				prevPageToken &&
				prevPageToken !== null &&
				prevPageToken !== undefined
			) {
				link = `/crm/business-user?id=${id}`;
			} else {
				link = `/crm/business-user?id=${id}`;
			}

			window.open(link, "_blank");
		} else if (selected === "Business-Orders") {
			let id = data["Order ID"];
			let type = data["Type"];
			let link = "";

			if (
				prevPageToken &&
				prevPageToken !== null &&
				prevPageToken !== undefined
			) {
				// link = `/crm/order-details?id=${id}&key=${selected}&nextPageToken=${prevPageToken}&path=${history?.location?.pathname}`;
				link = `/crm/order-details?id=${id}&type=${type}`;
			} else {
				// link = `/crm/order-details?id=${id}&path=${history?.location?.pathname}`;
				link = `/crm/order-details?id=${id}&type=${type}`;
			}

			window.open(link, "_blank");
		}else if (selected === "Merchant-Users") {
			let id = data["Merchant User Id"];
			let link = "";

			if (
				prevPageToken &&
				prevPageToken !== null &&
				prevPageToken !== undefined
			) {
				link = `/crm/merchant-user?id=${id}`;
			} else {
				link = `/crm/merchant-user?id=${id}`;
			}

			window.open(link, "_blank");
		}else if (selected === "Merchant-Users") {
			let id = data["Merchant User Id"];
			let link = "";

			if (
				prevPageToken &&
				prevPageToken !== null &&
				prevPageToken !== undefined
			) {
				link = `/crm/merchant-user?id=${id}`;
			} else {
				link = `/crm/merchant-user?id=${id}`;
			}

			window.open(link, "_blank");
		}else if (selected === "Grunner-Users") {
			let id = data["Grunner ID"];
			let link = "";

			if (
				prevPageToken &&
				prevPageToken !== null &&
				prevPageToken !== undefined
			) {
				link = `/crm/grunner-user?id=${id}`;
			} else {
				link = `/crm/grunner-user?id=${id}`;
			}

			window.open(link, "_blank");
		}
	};
	const handleSearch = (categoryId, inputValue) => {
		setCategoryId(categoryId);
		setSeacthQuery(inputValue);
	};
	const handleFilter = (filterId) => {
		setFilterId(filterId);
	};
	return (
		<>
			<ToastContainer limit={1}/>
			<div className={styles.mainContainer}>
				{loading ? (
					<div className={styles.clipLoader}>
						<ClipLoader color="#000" size={50} />
					</div>
				) : (
					<>
						<div className={styles.headerContainer}>
							<ArrowBackIcon
								fontSize="large"
								style={{ marginLeft: "-5px", cursor: "pointer" }}
								onClick={handleGoBack}
							/>
							<div className={styles.titleContainer}>
								<div className={styles.pageTitle}>CRM</div>
							</div>
						</div>
						<SearchBar
							savedCategoryId={categoryId}
							savedSearchQuery={savedSearchQuery}
							searchString={selected}
							onSearch={handleSearch}
						/>
						
						<div className={styles.dropDownContainer}>
						<Filter
							savedFilterId={savedFilterId}
							searchString={selected}
							onSelect={handleFilter}
						/>
							<div className={styles.dropDownContentContainer}>
								<button
									type="button"
									className={styles.dropDownBtn}
									onClick={toggleOptions}
									aria-haspopup="listbox"
									aria-expanded={isOptionsOpen}
								>
									<div className={styles.btnContent}>
										<span className="option-text-ph">
											{selected ? selected : "Select"}{" "}
										</span>
										{isOptionsOpen ? (
											<KeyboardArrowUpIcon
												viewBox="0 0 23 23"
												fontSize="small"
											/>
										) : (
											<ExpandMoreIcon viewBox="0 0 23 23" fontSize="small" />
										)}
									</div>
								</button>

								{isOptionsOpen ? (
									<ul
										role="listbox"
										tabIndex={-1}
										className={styles.ulContainer}
									>
										{crmContent?.map((entry) => {
											return (
												<Entry entry={entry} depth={1} key={entry?.title} />
											);
										})}
									</ul>
								) : null}
							</div>
						</div>
						<div>
							<DataTable
								data={data}
								onNextPage={handleNextPage}
								onPreviousPage={handlePreviousPage}
								onFirstPage={handleFirstPage}
								hasNextPage={hasNextPage}
								hasPrevPage={hasPrevPage}
								onView={handleView}
								showActionBtn={showActionBtn}
								colorCoded={colorCoded}
							/>
						</div>
					</>
				)}
			</div>
		</>
	);
}

export default CRM_Main_Page;
