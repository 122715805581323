/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArrowRightSharpIcon from "@mui/icons-material/ArrowRightSharp";

import { getOrdersByUserId } from "../../env/APIManager";

import DataTable from "../DataTable";

import styles from "./UserOrders.module.css";
function UserOrders({ id }) {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);

	const [nextPageToken, setNextPageToken] = useState("");
	const [prevPageToken, setPrevPageToken] = useState("");
	const [hasNextPage, setHasNextPage] = useState(false);
	const [hasPrevPage, setHasPrevPage] = useState(false);
	const [token, setToken] = useState("");
	const [showActionBtn, setShowActionBtn] = useState(false);

	const [currentPageToken, setCurrentPageToken] = useState("");

	const [isOptionsOpen, setIsOptionsOpen] = useState(false);

	const [selected, setSelected] = useState("");
	const search = window.location.search;
	const params = new URLSearchParams(search);

	const nextToken = params.get("nextPageToken");
	const savedKey = localStorage.getItem("userKey");

	let history = useHistory();

	const toggleOptions = () => {
		setIsOptionsOpen(!isOptionsOpen);
	};

	let filterContent = [
		{
			title: "Orders-Sent",
		},
		{
			title: "Orders-Received",
		},
	];

	useEffect(() => {
		let token =
			!localStorage.getItem("token")
				? ""
				: JSON.parse(localStorage.getItem("token"));
		setToken(token);
		if (
			currentPageToken &&
			currentPageToken !== null &&
			currentPageToken !== undefined
		) {
			getUserOrders(token, "currentPageToken");
		} else {
			getUserOrders(token);
		}
		localStorage.setItem("userKey", selected);
	}, [selected]);

	useEffect(() => {
		if (nextToken) {
			setCurrentPageToken(nextToken);
		}

		// if (key && key !== null && key !== undefined) {
		// 	setSelected(key);
		// 	localStorage.setItem("key", key);

		// 	return;
		// }

		if (savedKey) {
			function checkSavedKeyForKeyword(keyword) {
				return savedKey.includes(keyword);
			}

			for (const item of filterContent) {
				const title = item.title;

				if (checkSavedKeyForKeyword(title)) {
					setSelected(savedKey);
				} else {
					setSelected("Orders-Sent");
					localStorage.setItem("userKey", "Orders-Sent");
				}
			}
		} else {
			// Default state and save to local storage
			setSelected("Orders-Sent");
			localStorage.setItem("key", "Orders-Sent");
		}
	}, []);

	async function handleAPIResponse(res) {
		if (res?.code === "SUCCESS") {
			setData(res?.data);
			setNextPageToken(res?.data?.nextPageToken);
			setPrevPageToken(res?.data?.prevPageToken);
			setHasNextPage(res?.data?.hasMore);
			setHasPrevPage(res?.data?.hasLess);
			setLoading(false);
		} else if (res?.data?.code === "UNAUTHORIZED") {
			localStorage.clear();
			setLoading(false);
			toast.error(
				"You have been logged out. This may have been caused by using more than one device or browser"
			);
			history.push({ pathname: "/login" });
		} else {
			toast.error("Something went wrong, please try again later");
			setLoading(false);
			setData([]);
		}
	}

	const getUserOrders = async (token, string) => {
		let url;
		let data;
		try {
			setLoading(true);
			switch (selected) {
				case "Orders-Sent":
					if (string === "next") {
						url = `consumer/users/single/sent?userId=${id}&nextToken=${nextPageToken}`;
					} else if (string === "prev") {
						url = `consumer/users/single/sent?userId=${id}&prevToken=${prevPageToken}`;
					} else if (string === "currentPageToken") {
						url = `consumer/users/single/sent?userId=${id}&currentToken=${currentPageToken}`;
					} else {
						url = `consumer/users/single/sent?userId=${id}`;
					}
					data = await getOrdersByUserId(token, url);

					await handleAPIResponse(data, selected);
					setShowActionBtn(true);

					break;

				case "Orders-Received":
					if (string === "next") {
						url = `consumer/users/single/received?userId=${id}&nextToken=${nextPageToken}`;
					} else if (string === "prev") {
						url = `consumer/users/single/received?userId=${id}&prevToken=${prevPageToken}`;
					} else if (string === "currentPageToken") {
						url = `consumer/users/single/received?userId=${id}&currentToken=${currentPageToken}`;
					} else {
						url = `consumer/users/single/received?userId=${id}&`;
					}

					data = await getOrdersByUserId(token, url);

					await handleAPIResponse(data, selected);
					setShowActionBtn(true);

					break;

				default:
					// Handle other cases if needed
					break;
			}
		} catch (error) {
			toast.error("Something went wrong, please try again later");
			setLoading(false);
			setData([]);
		}
	};

	const handleNextPage = () => {
		getUserOrders(token, "next");
	};

	const handlePreviousPage = () => {
		getUserOrders(token, "prev");
	};

	const handleFirstPage = () => {
		getUserOrders(token);
	};
	const handleView = (data) => {
		let orderId = data["Order ID"];
		let type = data["Type"];
		let link = "";

		if (
			prevPageToken &&
			prevPageToken !== null &&
			prevPageToken !== undefined
		) {
			// link = `/crm/order-details?id=${orderId}&key=${selected}&nextPageToken=${prevPageToken}&path=${path}`;
			link = `/crm/order-details?id=${orderId}&type=${type}`;
		} else {
			// link = `/crm/order-details?id=${orderId}&path=${path}&key=${selected}`;
			link = `/crm/order-details?id=${orderId}&type=${type}`;
		}
		window.open(link, "_blank");
	};

	function Entry({ entry, parentTitle = "" }) {
		const [isVisible, setIsVisible] = useState(false);

		return (
			<div>
				<div
					className={`${styles.entry} ${
						selected === `${parentTitle}-${entry.title}` ? styles.selected : ""
					}`}
					onClick={() => {
						setIsVisible(!isVisible);
						handleTitleClick(
							entry.title,
							parentTitle,
							entry.children && entry.children.length > 0
						);
					}}
				>
					{entry?.children && <ArrowRightSharpIcon />}
					{entry?.title}
				</div>

				<div>
					{isVisible && (
						<div className={styles.childEntries}>
							{entry?.children?.map((childEntry) => {
								return (
									<Entry
										entry={childEntry}
										parentTitle={entry.title}
										key={childEntry.title}
									/>
								);
							})}
						</div>
					)}
				</div>
			</div>
		);
	}
	const handleTitleClick = (title, parentTitle, hasChildren) => {
		if (hasChildren) {
			return;
		} else {
			setNextPageToken("");
			setPrevPageToken("");
			setCurrentPageToken("");

			if (parentTitle?.length > 0) {
				setSelected(`${parentTitle}-${title}`);
				toggleOptions();
				return;
			} else {
				setSelected(title);
				toggleOptions();
			}
		}
	};

	return (
		<div>
			<div className={styles.dropDownContainer}>
				<div className={styles.dropDownContentContainer}>
					<button
						type="button"
						className={styles.dropDownBtn}
						onClick={toggleOptions}
						aria-haspopup="listbox"
						aria-expanded={isOptionsOpen}
					>
						<div className={styles.btnContent}>
							<span className="option-text-ph">
								{selected ? selected : "Select"}{" "}
							</span>
							{isOptionsOpen ? (
								<KeyboardArrowUpIcon viewBox="0 0 23 23" fontSize="small" />
							) : (
								<ExpandMoreIcon viewBox="0 0 23 23" fontSize="small" />
							)}
						</div>
					</button>

					{isOptionsOpen ? (
						<ul role="listbox" tabIndex={-1} className={styles.ulContainer}>
							{filterContent?.map((entry) => {
								return <Entry entry={entry} depth={1} key={entry?.title} />;
							})}
						</ul>
					) : null}
				</div>
			</div>



			{loading ? (
					<div className={styles.clipLoader}>
						<ClipLoader color="#000" size={50} />
					</div>
				) : (





			<div>
				<DataTable
					data={data}
					onNextPage={handleNextPage}
					onPreviousPage={handlePreviousPage}
					onFirstPage={handleFirstPage}
					hasNextPage={hasNextPage}
					hasPrevPage={hasPrevPage}
					onView={handleView}
					showActionBtn={showActionBtn}
				/>
			</div>
				)}
		</div>
	);
}

export default UserOrders;
