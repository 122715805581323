/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styles from "./Filter.module.css";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ClipLoader from "react-spinners/ClipLoader";

import { getFilterOptions } from "../../env/APIManager";

const Filter = ({
	searchString,
	onSelect,
	savedFilterId,
}) => {
	const [data, setData] = useState([]);
	const [selected, setSelected] = useState("");

	const [isOptionsOpen, setIsOptionsOpen] = useState(false);

	const [, setSelectedFilterId] = useState("");
	const [loading, setLoading] = useState(false);

	const history = useHistory();

	useEffect(() => {
		let token =
			!localStorage.getItem("token")
				? ""
				: JSON.parse(localStorage.getItem("token"));

		// Create a variable to track whether the component is still mounted
		let isMounted = true;

		// Inside the asynchronous function, check if the component is still mounted before updating the state
		async function fetchData() {
			try {
				const response = await getFilterOptions(
					token,
					searchString?.toLowerCase()
				);

				if (!isMounted) {
					return; // If the component is unmounted, exit early
				}

				if (response?.code === "SUCCESS") {
					setData(response?.data);
					setLoading(false);

					// Check if savedFilterId exists in the response data
					const savedCategory = response?.data.find(
						(option) => option.filterId === savedFilterId
					);

					if (savedCategory) {
						setSelected(savedCategory?.filterDisplayedName);
						setSelectedFilterId(savedFilterId);
					}
				} else if (response?.data?.code === "UNAUTHORIZED") {
					localStorage.clear();
					setLoading(false);
					toast.error(
						"You have been logged out. This may have been caused by using more than one device or browser"
					);
					history.push({ pathname: "/login" });
				} else {
					setLoading(false);
				}
			} catch (error) {
				console.error("API call error:", error);
				setLoading(false);
			}
		}

		if (searchString) {
			fetchData();
		} else {
			setData([]);
		}

		// Cleanup function to set isMounted to false when the component is unmounted
		return () => {
			isMounted = false;
		};
	}, [searchString]);

	const handleClear = () => {
		setSelectedFilterId("");
		onSelect("");
	};

	const toggleOptions = () => {
		setIsOptionsOpen(!isOptionsOpen);
	};

	const handleSelection = (option) => {
		setSelected(option?.filterDisplayedName);
		setSelectedFilterId(option?.filterId);
		setIsOptionsOpen(false);
		onSelect(option?.filterId);

	};

	return (
		<>
			<ToastContainer limit={1} />

			{loading ? (
				<div className={styles.clipLoader}>
					<ClipLoader color="#000" size={30} />
				</div>
			) : (
				<>
					{data?.length > 0 ? (
						<div className={styles.mainContainer}>
							<div className={styles.dropDownContainer}>
								<button
									type="button"
									className={styles.dropDownBtn}
									onClick={toggleOptions}
									aria-haspopup="listbox"
									aria-expanded={isOptionsOpen}
								>
									<div className={styles.btnContent}>
										<span className={styles.selected}>
											{selected ? selected : "Filter"}{" "}
										</span>
										{isOptionsOpen ? (
											<KeyboardArrowUpIcon
												viewBox="0 0 23 23"
												fontSize="small"
											/>
										) : (
											<ExpandMoreIcon viewBox="0 0 23 23" fontSize="small" />
										)}
									</div>
								</button>
								{isOptionsOpen ? (
									<ul
										role="listbox"
										tabIndex={-1}
										className={styles.ulContainer}
									>
										<div className={styles.btnContainer}>
											<button onClick={handleClear}
											className={styles.clearBtn}>Clear</button>
										</div>
										{data?.map((option) => {
											return (
												<div
													className={
														selected === option?.filterDisplayedName
															? styles.selectedOption
															: styles.option
													}
													key={option?.filterId}
													onClick={(e) => handleSelection(option)}
												>
													{option?.filterDisplayedName}
												</div>
											);
										})}
									</ul>
								) : null}
							</div>
						</div>
					) : null}
				</>
			)}
		</>
	);
};

export default Filter;
