import { mainServerURL, userServerURL } from "../../settings";

const baseURL = `${mainServerURL}/crm/v1`;
const secondaryBaseURL = userServerURL;

export async function addCrmNote(tokenId, id, note) {
  const url = `${baseURL}/note/single?primaryId=${id}`;

  var auth = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${tokenId}`,
    },
    body: JSON.stringify({ noteInfo: note }),
  };

  try {
    const res = await fetch(url, auth);
    let response = await res.json();
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function completeOrder(tokenId, orderId, type) {
  const url = `${baseURL}/orders/single/complete?orderId=${orderId}&type=${type}`;

  var auth = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${tokenId}`,
    },
  };

  try {
    const res = await fetch(url, auth);
    let response = await res.json();
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function cancelOrder(tokenId, orderId, type, data) {
  const url = `${baseURL}/orders/single/cancel?orderId=${orderId}&type=${type}`;

  var auth = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${tokenId}`,
    },
    body: JSON.stringify({ cancelInfo: data }),
  };

  try {
    const res = await fetch(url, auth);
    let response = await res.json();
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getBusinessOrdersByUserId(tokenId, apiUrl) {
  const url = `${baseURL}/` + apiUrl;

  var auth = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${tokenId}`,
    },
  };

  try {
    const res = await fetch(url, auth);
    let response = await res.json();

    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getBusinessUserDetails(tokenId, userId) {
  const url = `${baseURL}/business/users/single?userId=${userId}`;

  var auth = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${tokenId}`,
    },
  };

  try {
    const res = await fetch(url, auth);
    let response = await res.json();

    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getConsumerUserDetails(tokenId, userId) {
  const url = `${baseURL}/consumer/users/single?userId=${userId}`;

  var auth = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${tokenId}`,
    },
  };

  try {
    const res = await fetch(url, auth);
    let response = await res.json();
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getCRMData(tokenId, apiUrl) {
  const url = `${baseURL}/` + apiUrl;

  var auth = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${tokenId}`,
    },
  };

  try {
    const res = await fetch(url, auth);
    let response = await res.json();

    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getCRMNotes(tokenId, apiUrl) {
  const url = `${baseURL}` + apiUrl;

  var auth = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${tokenId}`,
    },
  };

  try {
    const res = await fetch(url, auth);
    let response = await res.json();

    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getFilterOptions(tokenId, searchQuery) {
  const url = `${baseURL}/filter_options/${searchQuery}`;

  var auth = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${tokenId}`,
    },
  };

  try {
    const res = await fetch(url, auth);
    let response = await res.json();
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getGrunnerUserDetails(tokenId, userId) {
  const url = `${baseURL}/grunner/users/single?grunnerUid=${userId}`;

  var auth = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${tokenId}`,
    },
  };

  try {
    const res = await fetch(url, auth);
    let response = await res.json();

    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getMerchantUserDetails(tokenId, userId) {
  const url = `${baseURL}/merchant/users/?merchantUid=${userId}`;

  var auth = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${tokenId}`,
    },
  };

  try {
    const res = await fetch(url, auth);
    let response = await res.json();
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getOrderDetails(tokenId, orderId, type) {
  const url = `${baseURL}/orders/single?orderId=${orderId}&type=${type}`;

  var auth = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${tokenId}`,
    },
  };

  try {
    const res = await fetch(url, auth);
    let response = await res.json();
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getOrdersByUserId(tokenId, apiUrl, skuId) {
  const url = `${baseURL}/` + apiUrl;

  var auth = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${tokenId}`,
    },
  };

  try {
    const res = await fetch(url, auth);
    let response = await res.json();

    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getSearchOptions(tokenId, searchQuery) {
  const url = `${baseURL}/categories/${searchQuery}`;

  var auth = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${tokenId}`,
    },
  };

  try {
    const res = await fetch(url, auth);
    let response = await res.json();
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getProductById(tokenId, productId) {
  const url = `${secondaryBaseURL}/g3/v2/shop/products/${productId}`;

  const auth = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${tokenId}`,
    },
  };

  try {
    const res = await fetch(url, auth);
    let response = await res.json();
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getProductsAll(tokenId) {
  const url = `${secondaryBaseURL}/g3/v2/shop/products/all`;

  const auth = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${tokenId}`,
    },
  };

  try {
    const res = await fetch(url, auth);
    let response = await res.json();
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getSkuDataG3(tokenId, skuId) {
  const url = skuId
    ? `${secondaryBaseURL}/g3/product/${skuId}`
    : `${secondaryBaseURL}/g3/product/all`;

  const auth = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${tokenId}`,
    },
  };

  try {
    const res = await fetch(url, auth);
    let response = await res.json();
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getTasksByGrunnerUid(tokenId, urlEnding) {
  const url = `${baseURL}/${urlEnding}`;

  var auth = {
    method: "GET",
    headers: {
      Authorization: `Bearer ${tokenId}`,
    },
  };

  try {
    const res = await fetch(url, auth);
    let response = await res.json();

    return response;
  } catch (err) {
    return err.response;
  }
}

export async function insertMerchantPostDataByMerchantUid(
  tokenId,
  merchantUid,
  postData
) {
  const url = `${baseURL}/merchant/users/single/?merchantUid=${merchantUid}`;

  var auth = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${tokenId}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ postData: postData }),
  };

  try {
    const res = await fetch(url, auth);
    let response = await res.json();
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function updateMerchantWithPatchDataByMerchantUid(
  tokenId,
  merchantUid,
  patchData
) {
  const url = `${baseURL}/merchant/users/single/?merchantUid=${merchantUid}`;

  var auth = {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${tokenId}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ patchData: patchData }),
  };

  try {
    const res = await fetch(url, auth);
    let response = await res.json();
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function updateUser(tokenId, id, data) {
  const url = `${baseURL}/consumer/users/single?userId=${id}`;

  var auth = {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${tokenId}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ userInfo: data }),
  };

  try {
    const res = await fetch(url, auth);
    let response = await res.json();

    return response;
  } catch (err) {
    return err.response;
  }
}
