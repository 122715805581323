/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ClipLoader from "react-spinners/ClipLoader";
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import { getOrderDetails, completeOrder, cancelOrder } from "../env/APIManager";
import styles from "./OrderDetails.module.css";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import ArrowDropUpSharp from "@mui/icons-material/ArrowDropUpSharp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import CRMNote from "./crm-notes/crmNote";

import Modal from "react-bootstrap/Modal";

const ExpandableDiv = ({ title, children }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const handleToggle = () => {
    setIsExpanded((prevState) => !prevState);
  };

  const showStyle = {
    height: "auto",
  };
  const hideStyle = {
    paddingTop: "4px",
    paddingBottom: "4px",
  };
  return (
    <div
      style={isExpanded ? showStyle : hideStyle}
      className={styles.collapseSection}
      key={title}
    >
      <div onClick={handleToggle} className={styles.mainTitleContainer}>
        {isExpanded ? <ArrowDropUpSharp /> : <ArrowDropDownSharpIcon />}
        <div className={styles.mainTitle}>{title}</div>
      </div>

      {isExpanded && <div className={styles.mainBodyContainer}>{children}</div>}
    </div>
  );
};
const OrderDetails = () => {
  const [loading, setLoading] = useState(false);
  const [orderDetails, setOrderDetails] = useState({});
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [completeBtnLoading, setCompleteBtnLoading] = useState(false);
  const [token, setToken] = useState("");
  const [selectedCancelId, setSelectedCancelId] = useState(null);

  const [customCancelReason, setCustomCancelReason] = useState("");

  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);

  const [isOptionsOpen, setIsOptionsOpen] = useState(false);

  const [cancelInfo, setCancelInfo] = useState({
    cancelId: null,
    cancelReason: "",
  });

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const id = params.get("id");
  const key = params.get("key");
  const type = params.get("type");
  const nextPageToken = params.get("nextPageToken");
  const path = params.get("path");

  let history = useHistory();

  async function getOrder(token, id) {
    setLoading(true);

    let data = await getOrderDetails(token, id, type);

    if (data?.code === "SUCCESS") {
      setOrderDetails(data?.data);
      setLoading(false);
    } else if (data?.data?.code === "UNAUTHORIZED") {
      localStorage.clear();
      setLoading(false);
      toast.error(
        "You have been logged out. This may have been caused by using more than one device or browser"
      );
      history.push({ pathname: "/login" });
    } else {
      toast.error("Something went wrong, please try again later");
      setLoading(false);
    }
  }
  useEffect(() => {
    let token = !localStorage.getItem("token")
        ? ""
        : JSON.parse(localStorage.getItem("token"));

    setToken(token);

    if (!id) {
      const querySeparator = path?.includes("?") ? "&" : "?";

      if (!path) {
        history.push(`/crm`);
        return;
      }
      if (nextPageToken && key) {
        history.push(
          `${path}${querySeparator}key=${key}&nextPageToken=${nextPageToken}`
        );
      } else {
        history.push(`${path}${querySeparator}key=${key}`);
      }
      return;
    }

    getOrder(token, id);
  }, []);

  const weekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const matchDay = (index, data) => {
    const matchedDict = data?.business_hours?.find(
      (data) => data?.dayOfWeekId === index
    );
    if (matchedDict) {
      if (matchedDict?.openAllDay) {
        return "Open All Day";
      } else if (matchedDict?.closedAllDay) {
        return "Closed All Day";
      }
      return matchedDict?.openTime && matchedDict?.closeTime
        ? `${getHoursSmall(matchedDict?.openTime)} - ${getHoursSmall(
            matchedDict?.closeTime
          )}`
        : "Not Selected";
    } else {
      return "Not Selected";
    }
  };
  const getHoursSmall = (hour) => {
    var AmOrPM = hour >= 12 ? " PM" : " AM";
    return (hour % 12 || 12).toString() + AmOrPM;
  };

  const getDateString = (timestamp) => {
    var formattedString = "";
    const date = new Date(timestamp);

    date
      .toDateString()
      .split(" ")
      .forEach((item, index) => {
        if (index === 0 || index === 2) formattedString += item + ", ";
        else {
          formattedString += item + " ";
        }
      });

    return formattedString;
  };

  const handleGoBack = () => {
    if (path && path !== null && path !== undefined) {
      const querySeparator = path?.includes("?") ? "&" : "?";

      if (nextPageToken && key) {
        history.push(
          `${path}${querySeparator}key=${key}&nextPageToken=${nextPageToken}`
        );
      } else if (key !== null && key !== undefined) {
        history.push(`${path}${querySeparator}key=${key}`);
      } else {
        history.push(`${path}`);
      }
    } else {
      history.push("/crm");
    }
  };

  const handleCompleteOrder = async () => {
    setCompleteBtnLoading(true);
    let data = await completeOrder(token, id, type);
    if (data?.code === "SUCCESS") {
      setCompleteBtnLoading(false);
      window.location.reload();
    } else if (data?.data?.code === "UNAUTHORIZED") {
      localStorage.clear();
      setCompleteBtnLoading(false);
      toast.error(
        "You have been logged out. This may have been caused by using more than one device or browser"
      );
      history.push({ pathname: "/login" });
    } else {
      if (data?.message) toast.error(data?.message);
      else {
        toast.error("Something went wrong, please try again later");
      }
      setCompleteBtnLoading(false);
    }
  };
  const handleCancelOrder = async () => {
    if (cancelInfo?.cancelId === null) {
      toast.error("Please select a reason for cancellation before submitting!");
      return;
    }
    if (cancelInfo?.cancelReason?.trim()?.length === 0) {
      toast.error("Please type in a custom reason before submitting!");
      return;
    }
    const isCancelInfoMissing =
      cancelInfo?.cancelId === null ||
      cancelInfo?.cancelReason === "" ||
      (cancelInfo?.cancelReason === "Other" && customCancelReason === "");

    if (!isCancelInfoMissing) {
      setCompleteBtnLoading(true);
      let data = await cancelOrder(token, id, type, cancelInfo);
      if (data?.code === "SUCCESS") {
        setCompleteBtnLoading(false);
        setShowCancelModal(false);
        window.location.reload();
      } else if (data?.data?.code === "UNAUTHORIZED") {
        localStorage.clear();
        setCompleteBtnLoading(false);
        toast.error(
          "You have been logged out. This may have been caused by using more than one device or browser"
        );
        history.push({ pathname: "/login" });
      } else {
        if (data?.data?.message) toast.error(data?.data?.message);
        else {
          toast.error("Something went wrong, please try again later");
        }
        setCompleteBtnLoading(false);
      }
    } else {
      if (cancelInfo?.cancelReason === "Other" && customCancelReason === "") {
        toast.error("Please type in a custom reason before submitting!");
        return;
      }
    }
  };

  function displayCompleteModal() {
    setShowCompleteModal(true);
  }
  function displayCancelModal() {
    setShowCancelModal(true);
  }

  const options = [
    {
      categoryName: "By Gesture",
      categoryId: 1,
      categoryOptions: [
        {
          cancelId: "ge-01",
          cancelReason: "Can't fulfill",
        },
        {
          cancelId: "ge-02",
          cancelReason: "Other",
        },
      ],
    },
    {
      categoryName: "By Merchant",
      categoryId: 2,
      categoryOptions: [
        {
          cancelId: "de-01",
          cancelReason: "Out of Stock",
        },
        {
          cancelId: "de-02",
          cancelReason: "Other(for example Emergency)",
        },
        {
          cancelId: "de-03",
          cancelReason: "Other",
        },
      ],
    },
    {
      categoryName: "By Grunner",
      categoryId: 2,
      categoryOptions: [
        {
          cancelId: "gr-01",
          cancelReason: "GRunner Unavailable",
        },
        {
          cancelId: "gr-02",
          cancelReason: "Other",
        },
      ],
    },
    {
      categoryName: "By User",
      categoryId: 2,
      categoryOptions: [
        {
          cancelId: "co-01",
          cancelReason: "Changed Mind",
        },
        {
          cancelId: "co-02",
          cancelReason: "Found Better Option",
        },
        {
          cancelId: "co-03",
          cancelReason: "Other",
        },
      ],
    },
  ];

  const handleOptionSelect = (cancelId, cancelReason) => {
    setSelectedCancelId(cancelId);
    setCancelInfo({
      cancelId: cancelId,
      cancelReason: cancelReason,
    });
    setCustomCancelReason("");
  };

  const handleCustomReasonChange = (event) => {
    const otherOption = options[selectedCategoryIndex]?.categoryOptions.find(
      (option) => option.cancelReason === "Other"
    );
    if (otherOption) {
      setSelectedCancelId(otherOption.cancelId);
      setCancelInfo({
        cancelId: otherOption.cancelId,
        cancelReason: event.target.value,
      });
    } else {
      setSelectedCancelId(null);
      setCancelInfo({
        cancelId: null,
        cancelReason: event.target.value,
      });
    }
    setCustomCancelReason(event.target.value);
  };

  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };

  const handleSelectOption = (category, index) => {
    setSelectedCategoryIndex(index);
    setIsOptionsOpen(false);
    setCustomCancelReason("");
    setCancelInfo({
      cancelId: null,
      cancelReason: "",
    });
    setSelectedCancelId(null);
  };

  function getDeliveryDate() {
    if (orderDetails?.deliveryDate_text?.length > 0) {
      return orderDetails?.deliveryDate_text;
    } else {
      return orderDetails?.deliveryDate
        ? new Date(orderDetails?.deliveryDate).toLocaleDateString("en-US")
        : "N/A";
    }
  }

  function getDeliveryTime() {
    if (orderDetails?.deliveryTime_text?.length > 0) {
      return orderDetails?.deliveryTime_text;
    } else {
      return orderDetails?.deliveryTime
        ? new Date(orderDetails?.deliveryTime).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          })
        : "";
    }
  }

  return (
    <>
      <ToastContainer limit={1} />
      <div className={styles.mainContainer}>
        {loading ? (
          <div className={styles.clipLoader}>
            <ClipLoader color="#000" size={50} />
          </div>
        ) : orderDetails && Object.keys(orderDetails)?.length > 0 ? (
          <>
            <Modal
              show={showCompleteModal}
              animation={true}
              style={{ marginTop: "20vh", paddingRight: "0" }}
            >
              <Modal.Body>
                <div
                  style={{
                    display: "flex",
                    backgroundColor: "#fff",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "10px !important",
                  }}
                >
                  <div
                    style={{
                      fontFamily: "Mulish",
                      fontSize: "18px",
                      fontWeight: "700",
                    }}
                  >
                    Are you sure you want to mark the order as completed?
                  </div>
                </div>
              </Modal.Body>

              <Modal.Footer
                style={{
                  display: "flex",
                  backgroundColor: "#fff",
                  justifyContent: "center",
                }}
              >
                <button
                  className={styles.cancelBtn}
                  onClick={() => setShowCompleteModal(false)}
                  disabled={completeBtnLoading}
                >
                  Cancel
                </button>

                <button
                  className={styles.confirmBtn}
                  onClick={() => handleCompleteOrder()}
                  disabled={completeBtnLoading}
                >
                  {completeBtnLoading ? (
                    <div className={styles.btnClipLoader}>
                      <ClipLoader color="#fff" size={20} />
                    </div>
                  ) : (
                    "Confirm"
                  )}
                </button>
              </Modal.Footer>
            </Modal>

            <Modal
              show={showCancelModal}
              animation={true}
              style={{ marginTop: "20vh", paddingRight: "0" }}
            >
              <Modal.Body>
                <div
                  style={{
                    display: "flex",
                    backgroundColor: "#fff",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "10px !important",
                    width: "80%",
                    margin: "auto",
                  }}
                >
                  <div
                    style={{
                      fontFamily: "Mulish",
                      fontSize: "18px",
                      fontWeight: "700",
                    }}
                  >
                    Please select a reason for cancellation
                  </div>
                  <div className={styles.selectContainer}>
                    <div className={styles.dropDownContainer}>
                      <div className={styles.dropDownContentContainer}>
                        <button
                          type="button"
                          className={styles.dropDownBtn}
                          onClick={toggleOptions}
                          aria-haspopup="listbox"
                          aria-expanded={isOptionsOpen}
                        >
                          <div className={styles.btnContent}>
                            <span className="option-text-ph">
                              {options[selectedCategoryIndex]?.categoryName}
                            </span>
                            {isOptionsOpen ? (
                              <KeyboardArrowUpIcon
                                viewBox="0 0 23 23"
                                fontSize="small"
                              />
                            ) : (
                              <ExpandMoreIcon
                                viewBox="0 0 23 23"
                                fontSize="small"
                              />
                            )}
                          </div>
                        </button>

                        {isOptionsOpen ? (
                          <ul
                            role="listbox"
                            tabIndex={-1}
                            className={styles.ulContainer}
                          >
                            {options.map((category, index) => (
                              <div
                                key={index}
                                value={index}
                                className={styles.option}
                                onClick={() =>
                                  handleSelectOption(category, index)
                                }
                              >
                                {category.categoryName}
                              </div>
                            ))}
                          </ul>
                        ) : null}
                      </div>
                    </div>

                    {!isOptionsOpen ? (
                      <div className={styles.checkBoxContainer}>
                        {options[selectedCategoryIndex]?.categoryOptions.map(
                          (option, index) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <label className={styles.customCheckmark}>
                                <input
                                  type="radio"
                                  checked={option.cancelId === selectedCancelId}
                                  onChange={() =>
                                    handleOptionSelect(
                                      option.cancelId,
                                      option.cancelReason
                                    )
                                  }
                                />
                                <span className={styles.checkmark}></span>
                                <span className={styles.label}>
                                  {option?.cancelReason}
                                </span>
                              </label>
                              {option.cancelReason === "Other" && (
                                <div>
                                  <input
                                    type="text"
                                    placeholder="Type custom reason"
                                    value={customCancelReason}
                                    onChange={handleCustomReasonChange}
                                    className={styles.input}
                                  />
                                </div>
                              )}
                            </div>
                          )
                        )}
                      </div>
                    ) : null}
                  </div>
                </div>
              </Modal.Body>

              <Modal.Footer
                style={{
                  display: "flex",
                  backgroundColor: "#fff",
                  justifyContent: "center",
                }}
              >
                <button
                  className={styles.cancelBtn}
                  onClick={() => setShowCancelModal(false)}
                  disabled={completeBtnLoading}
                >
                  Cancel
                </button>

                <button
                  className={styles.confirmBtn}
                  onClick={handleCancelOrder}
                  disabled={completeBtnLoading}
                >
                  {completeBtnLoading ? (
                    <div className={styles.btnClipLoader}>
                      <ClipLoader color="#fff" size={20} />
                    </div>
                  ) : (
                    "Confirm"
                  )}
                </button>
              </Modal.Footer>
            </Modal>
            <div className={styles.headerContainerOrders}>
              <div className={styles.navContainer}>
                <ArrowBackIcon
                  fontSize="large"
                  style={{ marginLeft: "-5px", cursor: "pointer" }}
                  onClick={handleGoBack}
                />
                <div className={styles.titleContainer}>
                  <div className={styles.pageTitle}>
                    Order #
                    {orderDetails?.orderId?.substring(
                      orderDetails?.orderId?.length - 5
                    )}{" "}
                  </div>
                </div>
              </div>
              {type === "consumer" ? (
                !orderDetails?.completedTime && !orderDetails?.cancelled ? (
                  <div className={styles.btnContainer}>
                    <button
                      onClick={displayCompleteModal}
                      className={styles.actionBtn}
                    >
                      Complete Order
                    </button>
                    <button
                      onClick={displayCancelModal}
                      className={styles.actionBtn}
                    >
                      Cancel Order
                    </button>
                  </div>
                ) : null
              ) : null}
            </div>

            {/* order information section */}
            <ExpandableDiv title="Order Information">
              <div className={styles.container}>
                <div className={styles.contentContainer}>
                  <div className={styles.bodyInfoContainer}>
                    <div className={styles.bodyContainer}>
                      <div className={styles.bodyTitle}>Created</div>
                      <div>
                        {orderDetails?.timeOrderPlaced
                          ? new Date(
                              orderDetails?.timeOrderPlaced
                            ).toLocaleDateString("en-US") +
                            " " +
                            new Date(
                              orderDetails?.timeOrderPlaced
                            ).toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                            })
                          : "N/A"}
                      </div>
                    </div>
                    <div className={styles.bodyContainer}>
                      <div className={styles.bodyTitle}>Type</div>
                      <div>
                        {orderDetails?.type ? orderDetails?.type : "N/A"}
                      </div>
                    </div>
                    <div className={styles.bodyContainer}>
                      <div className={styles.bodyTitle}>Scheduled</div>
                      <div>
                        {/* Display delivery date */}
                        {getDeliveryDate()} &nbsp;
                        {/* Display delivery time */}
                        {getDeliveryTime()}
                      </div>
                    </div>
                    <div className={styles.bodyContainer}>
                      <div className={styles.bodyTitle}>Scheduled by User</div>
                      <div>
                        {orderDetails?.smartSendScheduledDate
                          ? new Date(
                              orderDetails?.smartSendScheduledDate
                            ).toLocaleDateString("en-US")
                          : "N/A"}
                      </div>
                    </div>
                    <div className={styles.bodyContainer}>
                      <div className={styles.bodyTitle}>Delivered</div>
                      <div>
                        {orderDetails?.completedTime
                          ? new Date(
                              orderDetails?.completedTime
                            ).toLocaleDateString("en-US") +
                            " " +
                            new Date(
                              orderDetails?.completedTime
                            ).toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                            })
                          : "N/A"}
                      </div>
                    </div>
                    <div className={styles.bodyContainer}>
                      <div className={styles.bodyTitle}>
                        Marked as Delivered
                      </div>
                      <div>
                        {orderDetails?.completedBy
                          ? orderDetails?.completedBy
                          : "N/A"}
                      </div>
                    </div>
                    <div className={styles.bodyContainer}>
                      <div className={styles.bodyTitle}>Cancelled</div>
                      <div>{orderDetails?.cancelled ? "Yes" : "No"}</div>
                    </div>
                    <div className={styles.bodyContainer}>
                      <div className={styles.bodyTitle}>Cancelled Time</div>
                      <div>
                        {orderDetails?.cancelledTime
                          ? new Date(
                              orderDetails?.cancelledTime
                            ).toLocaleDateString("en-US") +
                            " " +
                            new Date(
                              orderDetails?.cancelledTime
                            ).toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                            })
                          : "N/A"}
                      </div>
                    </div>
                    <div className={styles.bodyContainer}>
                      <div className={styles.bodyTitle}>Cancelled Reason</div>
                      <div>
                        {orderDetails?.cancelReason
                          ? orderDetails?.cancelReason
                          : "N/A"}
                      </div>
                    </div>
                    <div className={styles.bodyContainer}>
                      <div className={styles.bodyTitle}>
                        Marked as Cancelled
                      </div>
                      <div>
                        {orderDetails?.cancelledBy
                          ? orderDetails?.cancelledBy
                          : "N/A"}
                      </div>
                    </div>
                    <div className={styles.bodyContainer}>
                      <div className={styles.bodyTitle}>Gifting Method</div>
                      <div>
                        {orderDetails?.giftingMethod === 1
                          ? "Smartsend"
                          : orderDetails?.giftingMethod === 2
                          ? "Old School"
                          : orderDetails?.giftingMethod === 3
                          ? "Gesture Anywhere"
                          : "N/A"}
                      </div>
                    </div>
                    <div className={styles.bodyContainer}>
                      <div className={styles.bodyTitle}>Status</div>
                      <div>
                        {orderDetails?.orderStatus === 1
                          ? "Pending"
                          : orderDetails?.orderStatus === 2
                          ? "Scheduled"
                          : orderDetails?.orderStatus === 3
                          ? "Delivered"
                          : orderDetails?.orderStatus === 4
                          ? "Cancelled"
                          : "N/A"}
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles.contentContainer}>
                  <div className={styles.bodyInfoContainer}>
                    {!orderDetails?.merchants ||
                    orderDetails?.merchants?.length === 0
                      ? orderDetails?.products?.map((el) => {
                          return (
                            <div className={styles.productsContainer}>
                              <div className={styles.bodyContainer}>
                                <div className={styles.bodyTitle}>Sku Name</div>
                                <div>{el?.skuName ? el?.skuName : "N/A"}</div>
                              </div>
                              <div className={styles.bodyContainer}>
                                <div className={styles.bodyTitle}>Sku Id</div>
                                <div>{el?.skuId ? el?.skuId : "N/A"}</div>
                              </div>
                              <div className={styles.bodyContainer}>
                                <div className={styles.bodyTitle}>
                                  Sku Amount
                                </div>
                                <div>
                                  {el?.skuAmount ? "$" + el?.skuAmount : "N/A"}
                                </div>
                              </div>
                              <div className={styles.bodyContainer}>
                                <div className={styles.bodyTitle}>
                                  Sku Count
                                </div>
                                <div>{el?.count ? el?.count : "N/A"}</div>
                              </div>
                              <div className={styles.bodyContainer}>
                                <div className={styles.bodyTitle}>
                                  {" "}
                                  Can we deliver this product to the recipient's
                                  address?
                                </div>
                                <div>
                                  <div>
                                    {el?.isProductDeliverable ? "Yes" : "No"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      : null}

                    {orderDetails?.customGesture ? (
                      <>
                        <div className={styles.bodyContainer}>
                          <div className={styles.bodyTitle}>
                            Custom Gesture User Notes
                          </div>
                          <div>
                            {orderDetails?.customGesture?.notes
                              ? orderDetails?.customGesture?.notes
                              : "N/A"}
                          </div>
                        </div>
                        <div className={styles.bodyContainer}>
                          <div className={styles.bodyTitle}>Custom Gesture</div>
                          <div>
                            {orderDetails?.customGesture?.products
                              ? orderDetails?.customGesture?.products?.map(
                                  (el) => {
                                    return (
                                      <>
                                        <div
                                          key={el}
                                          className={styles.bodyContainer}
                                        >
                                          <li>{el}</li>
                                        </div>
                                      </>
                                    );
                                  }
                                )
                              : null}
                          </div>
                        </div>
                      </>
                    ) : null}

                    <div className={styles.bodyContainer}>
                      <div className={styles.bodyTitle}>Tip</div>
                      <div>
                        {orderDetails?.tip ? "$" + orderDetails?.tip : "N/A"}
                      </div>
                    </div>
                    <div className={styles.bodyContainer}>
                      <div className={styles.bodyTitle}>Order Total</div>
                      <div>
                        {orderDetails?.orderAmount
                          ? "$" + orderDetails?.orderAmount
                          : "N/A"}
                      </div>
                    </div>
                    <div className={styles.bodyContainer}>
                      <div className={styles.bodyTitle}>Gesture Fee</div>
                      <div>
                        {orderDetails?.gestureFee
                          ? "$" + orderDetails?.gestureFee
                          : "N/A"}
                      </div>
                    </div>
                    {orderDetails?.merchants &&
                    orderDetails?.merchants?.length > 0 ? (
                      <div className={styles.bodyContainer}>
                        <div className={styles.bodyTitle}>Merchant Payouts</div>
                        <div>
                          {orderDetails?.merchants
                            ? orderDetails?.merchants?.length > 0 &&
                              orderDetails?.merchants?.map((merchant, i) => {
                                return (
                                  <div key={i}>
                                    {merchant?.merchantName} -
                                    {merchant?.merchantPayout
                                      ? "$" + merchant?.merchantPayout
                                      : "N/A"}
                                  </div>
                                );
                              })
                            : "N/A"}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </ExpandableDiv>

            {/* merchant information section */}
            <ExpandableDiv title="Merchant Information">
              {orderDetails?.merchants &&
              orderDetails?.merchants?.length > 0 ? (
                orderDetails?.merchants?.map((merchant, index) => (
                  <ExpandableDiv key={index} title={`${merchant.merchantName}`}>
                    <div className={styles.container}>
                      <div className={styles.contentContainer}>
                        <div className={styles.bodyInfoContainer}>
                          <div className={styles.bodyContainer}>
                            <div className={styles.bodyTitle}>
                              Sent To Merchant
                            </div>
                            <div>
                              {merchant.sentTime
                                ? new Date(
                                    merchant.sentTime
                                  ).toLocaleDateString("en-US") +
                                  " " +
                                  new Date(
                                    merchant.sentTime
                                  ).toLocaleTimeString([], {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  })
                                : "N/A"}
                            </div>
                          </div>
                          <div className={styles.bodyContainer}>
                            <div className={styles.bodyTitle}>
                              Acknowledged by Merchant
                            </div>
                            <div>{merchant.acknowledged ? "yes" : "no"}</div>
                          </div>

                          <div className={styles.bodyContainer}>
                            <div className={styles.bodyTitle}>
                              Accepted by Merchant
                            </div>
                            <div>
                              {merchant.acceptedTime
                                ? new Date(
                                    merchant.acceptedTime
                                  ).toLocaleDateString("en-US") +
                                  " " +
                                  new Date(
                                    merchant.acceptedTime
                                  ).toLocaleTimeString([], {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  })
                                : "N/A"}
                            </div>
                          </div>
                          <div className={styles.bodyContainer}>
                            <div className={styles.bodyTitle}>
                              Prepared by Merchant
                            </div>
                            <div>
                              {merchant.completedTime
                                ? new Date(
                                    merchant.completedTime
                                  ).toLocaleDateString("en-US") +
                                  " " +
                                  new Date(
                                    merchant.completedTime
                                  ).toLocaleTimeString([], {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  })
                                : "N/A"}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className={styles.contentContainer}>
                        <div className={styles.bodyInfoContainer}>
                          {merchant?.cancelledTime ? (
                            <>
                              <div className={styles.bodyContainer}>
                                <div className={styles.bodyTitle}>
                                  Cancelled by Merchant
                                </div>
                                <div>
                                  {merchant?.cancelledTime
                                    ? new Date(
                                        merchant?.cancelledTime
                                      ).toLocaleDateString("en-US") +
                                      " " +
                                      new Date(
                                        merchant?.cancelledTime
                                      ).toLocaleTimeString([], {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                      })
                                    : "N/A"}
                                </div>
                              </div>
                              <div className={styles.bodyContainer}>
                                <div className={styles.bodyTitle}>
                                  Cancel Reason
                                </div>
                                <div>
                                  {merchant?.cancelReason
                                    ? merchant?.cancelReason
                                    : "N/A"}
                                </div>
                              </div>
                            </>
                          ) : null}

                          <div className={styles.bodyContainer}>
                            <div className={styles.bodyTitle}>
                              Picked by Grunner
                            </div>
                            <div>
                              {merchant.grunnerPickedTime
                                ? new Date(
                                    merchant.grunnerPickedTime
                                  ).toLocaleDateString("en-US") +
                                  " " +
                                  new Date(
                                    merchant.grunnerPickedTime
                                  ).toLocaleTimeString([], {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  })
                                : "N/A"}
                            </div>
                          </div>
                          <div className={styles.bodyContainer}>
                            <div className={styles.bodyTitle}>
                              Delivered by Grunner
                            </div>
                            <div>
                              {merchant.deliveredTime
                                ? new Date(
                                    merchant.deliveredTime
                                  ).toLocaleDateString("en-US") +
                                  " " +
                                  new Date(
                                    merchant.deliveredTime
                                  ).toLocaleTimeString([], {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  })
                                : "N/A"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={styles.container}>
                      <div className={styles.contentContainer}>
                        <div className={styles.bodyInfoContainer}>
                          <div className={styles.bodyContainer}>
                            <div className={styles.bodyTitle}>Address</div>
                            <div>
                              {merchant.merchantLocation
                                ? merchant.merchantLocation
                                : "N/A"}
                            </div>
                          </div>
                          <div className={styles.bodyContainer}>
                            <div className={styles.bodyTitle}>Phone Number</div>
                            <div>
                              {merchant.merchant_phoneNumber
                                ? merchant.merchant_phoneNumber
                                : "N/A"}
                            </div>
                          </div>
                          <div className={styles.bodyContainer}>
                            <div className={styles.bodyTitle}>Email</div>
                            <div>
                              {merchant.merchant_email
                                ? merchant.merchant_email
                                : "N/A"}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className={styles.contentContainer}>
                        <div className={styles.bodyInfoContainer}>
                          <div className={styles.bodyContainer}>
                            <div className={styles.bodyTitle}>Sku Name</div>
                            <div>
                              {merchant.skuName ? merchant.skuName : "N/A"}
                            </div>
                          </div>
                          <div className={styles.bodyContainer}>
                            <div className={styles.bodyTitle}>Sku Id</div>
                            <div>{merchant.skuId ? merchant.skuId : "N/A"}</div>
                          </div>
                          <div className={styles.bodyContainer}>
                            <div className={styles.bodyTitle}>Total</div>
                            <div>
                              {merchant.total ? "$" + merchant.total : "N/A"}
                            </div>
                          </div>
                          <div className={styles.bodyContainer}>
                            <div className={styles.bodyTitle}>
                              Can we deliver this product to the recipient's
                              address?
                            </div>
                            <div>
                              {merchant.isProductDeliverable ? "Yes" : "No"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={styles.container}>
                      <div className={styles.contentContainer}>
                        <div className={styles.bodyInfoContainer}>
                          <div className={styles.bodyContainer}>
                            <div className={styles.bodyTitle}>
                              Business Hours
                            </div>

                            {merchant?.business_hours?.length > 0
                              ? weekDays?.map((day, index) => (
                                  <div
                                    className={styles.hoursContainer}
                                    key={index}
                                  >
                                    <div>{day}</div>
                                    <div>{matchDay(index, merchant)}</div>
                                  </div>
                                ))
                              : "No Information"}
                          </div>
                        </div>
                      </div>

                      <div className={styles.specialHourscontentContainer}>
                        <div className={styles.bodyInfoContainer}>
                          <div className={styles.bodyContainer}>
                            <div className={styles.bodyTitle}>
                              Special Hours
                            </div>
                            {merchant?.special_hours?.length > 0
                              ? merchant?.special_hours?.map((data, index) => (
                                  <div
                                    className={styles.hoursContainer}
                                    key={index}
                                  >
                                    <div>{getDateString(data?.date)}</div>
                                    {data?.openAllDay && (
                                      <div>Open All Day</div>
                                    )}
                                    {data?.closedAllDay && (
                                      <div>Closed All Day</div>
                                    )}
                                    {!data?.openAllDay &&
                                      !data?.closedAllDay && (
                                        <div>
                                          {getHoursSmall(data?.openTime)} -{" "}
                                          {getHoursSmall(data?.closeTime)}
                                        </div>
                                      )}
                                  </div>
                                ))
                              : "No Information"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </ExpandableDiv>
                ))
              ) : (
                <div className={styles.noDataContainer}>
                  <div className={styles.contentContainer}>
                    <div className={styles.bodyInfoContainer}>
                      <div className={styles.bodyContainer}>
                        <div className={styles.bodyTitle}>
                          This order does not currently have an assigned
                          merchant.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </ExpandableDiv>

            {/* sender information section */}
            <ExpandableDiv title="Sender Information">
              <div className={styles.container}>
                <div className={styles.contentContainer}>
                  <div className={styles.bodyInfoContainer}>
                    <div className={styles.bodyContainer}>
                      <div className={styles.bodyTitle}>Company</div>
                      <div>
                        {orderDetails?.senderCompany
                          ? orderDetails?.senderCompany
                          : "N/A"}
                      </div>
                    </div>
                    <div className={styles.bodyContainer}>
                      <div className={styles.bodyTitle}>Name</div>
                      <div>
                        {orderDetails?.sender_name
                          ? orderDetails?.sender_name
                          : "N/A"}
                      </div>
                    </div>
                    <div className={styles.bodyContainer}>
                      <div className={styles.bodyTitle}>Phone Number</div>
                      <div>
                        {orderDetails?.sender_phone
                          ? orderDetails?.sender_phone
                          : "N/A"}
                      </div>
                    </div>
                    <div className={styles.bodyContainer}>
                      <div className={styles.bodyTitle}>Email</div>
                      <div>
                        {orderDetails?.sender_email
                          ? orderDetails?.sender_email
                          : "N/A"}
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles.contentContainer}>
                  <div className={styles.bodyInfoContainer}>
                    <div className={styles.bodyContainer}>
                      <div className={styles.bodyTitle}>Notes</div>
                      <div>
                        {orderDetails?.senderDeliveryNotes
                          ? orderDetails?.senderDeliveryNotes
                          : "N/A"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ExpandableDiv>

            {/* recipient information section */}
            <ExpandableDiv title="Recipient Information">
              <div className={styles.container}>
                <div className={styles.contentContainer}>
                  <div className={styles.bodyInfoContainer}>
                    <div className={styles.bodyContainer}>
                      <div className={styles.bodyTitle}>Company</div>
                      <div>
                        {orderDetails?.receipientCompany
                          ? orderDetails?.receipientCompany
                          : "N/A"}
                      </div>
                    </div>
                    <div className={styles.bodyContainer}>
                      <div className={styles.bodyTitle}>Name</div>
                      <div>
                        {orderDetails?.recipientName
                          ? orderDetails?.recipientName
                          : "N/A"}
                      </div>
                    </div>
                    <div className={styles.bodyContainer}>
                      <div className={styles.bodyTitle}>Address</div>
                      <div>
                        {orderDetails?.recipientAddress
                          ? orderDetails?.recipientAddress
                          : "N/A"}
                      </div>
                    </div>
                    <div className={styles.bodyContainer}>
                      <div className={styles.bodyTitle}>Phone Number</div>
                      <div>
                        {orderDetails?.recipientPhone
                          ? orderDetails?.recipientPhone
                          : "N/A"}
                      </div>
                    </div>
                    <div className={styles.bodyContainer}>
                      <div className={styles.bodyTitle}>Email</div>
                      <div>
                        {orderDetails?.recipientEmail
                          ? orderDetails?.recipientEmail
                          : "N/A"}
                      </div>
                    </div>

                    {orderDetails?.giftingMethod === 1 ? (
                      <div className={styles.bodyContainer}>
                        <div className={styles.bodyTitle}>
                          Smartsend Notifications
                        </div>

                        {orderDetails?.smartSendNotificationTimes &&
                        orderDetails?.smartSendNotificationTimes?.length > 0 ? (
                          <>
                            <div>
                              {orderDetails?.recipientName
                                ? orderDetails?.recipientName
                                : "Recipient"}{" "}
                              was notified on:
                            </div>
                            {orderDetails?.smartSendNotificationTimes?.map(
                              (notification, index) => (
                                <div key={"psx" + index}>
                                  {new Date(notification).toLocaleString(
                                    "en-US"
                                  )}
                                  <br />
                                </div>
                              )
                            )}
                          </>
                        ) : (
                          (orderDetails?.recipientName
                            ? orderDetails?.recipientName
                            : "Recipient") +
                          " " +
                          "hasn't been notified yet"
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>

                <div className={styles.contentContainer}>
                  <div className={styles.bodyInfoContainer}>
                    <div className={styles.bodyContainer}>
                      <div className={styles.bodyTitle}>Notes</div>
                      <div>
                        {orderDetails?.recipientDeliveryInstructions
                          ? orderDetails?.recipientDeliveryInstructions
                          : "N/A"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ExpandableDiv>

            {/* grunner information section */}
            <ExpandableDiv title="Grunner Information">
              {orderDetails?.grunnerUid && orderDetails?.grunnerUid !== null ? (
                <div className={styles.container}>
                  <div className={styles.contentContainer}>
                    <div className={styles.bodyInfoContainer}>
                      <div className={styles.bodyContainer}>
                        <div className={styles.bodyTitle}>Task Id</div>
                        <div>
                          {orderDetails?.grunner_taskId
                            ? orderDetails?.grunner_taskId?.substring(
                                orderDetails?.grunner_taskId?.length - 5
                              )
                            : "N/A"}
                        </div>
                      </div>
                      <div className={styles.bodyContainer}>
                        <div className={styles.bodyTitle}>Name</div>
                        <div>
                          {orderDetails?.grunner_name
                            ? orderDetails?.grunner_name
                            : "N/A"}
                        </div>
                      </div>
                      <div className={styles.bodyContainer}>
                        <div className={styles.bodyTitle}>Address</div>
                        <div>
                          {orderDetails?.grunner_address
                            ? orderDetails?.grunner_address
                            : "N/A"}
                        </div>
                      </div>

                      <div className={styles.bodyContainer}>
                        <div className={styles.bodyTitle}>Phone Number</div>
                        <div>
                          {orderDetails?.grunner_phone
                            ? orderDetails?.grunner_phone
                            : "N/A"}
                        </div>
                      </div>
                      <div className={styles.bodyContainer}>
                        <div className={styles.bodyTitle}>Email</div>
                        <div>
                          {orderDetails?.grunner_email
                            ? orderDetails?.grunner_email
                            : "N/A"}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={styles.contentContainer}>
                    <div className={styles.bodyInfoContainer}>
                      <div className={styles.bodyContainer}>
                        <div className={styles.bodyTitle}>
                          Background Check Status
                        </div>
                        <div>
                          {orderDetails?.grunner_backgroundCheckStatus
                            ? orderDetails?.grunner_backgroundCheckStatus
                                ?.gestureStatus
                              ? "Completed and Approved"
                              : "Completed and Not Approved"
                            : "Not Completed"}
                        </div>
                      </div>
                      <div className={styles.bodyContainer}>
                        <div className={styles.bodyTitle}>
                          Identity Check Status
                        </div>
                        <div>
                          {orderDetails?.grunner_identityStatus
                            ? orderDetails?.grunner_identityStatus
                                ?.gestureStatus
                              ? "Completed and Approved"
                              : "Completed and Not Approved"
                            : "Not Completed"}
                        </div>
                      </div>
                      <div className={styles.bodyContainer}>
                        <div className={styles.bodyTitle}>
                          Verification Status
                        </div>
                        <div>
                          {orderDetails?.grunner_verificationState
                            ? orderDetails?.grunner_verificationState
                            : "N/A"}
                        </div>
                      </div>
                      <div className={styles.bodyContainer}>
                        <div className={styles.bodyTitle}>Online Status</div>
                        <div>
                          {orderDetails?.grunner_online_status !== undefined &&
                          orderDetails?.grunner_online_status !== null
                            ? orderDetails?.grunner_online_status === 0
                              ? "Offline"
                              : orderDetails?.grunner_online_status === 1
                              ? "Paused"
                              : orderDetails?.grunner_online_status === 2
                              ? "Online"
                              : "N/A"
                            : "N/A"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className={styles.noDataContainer}>
                  <div className={styles.contentContainer}>
                    <div className={styles.bodyInfoContainer}>
                      <div className={styles.bodyContainer}>
                        <div className={styles.bodyTitle}>
                          This order does not currently have an assigned
                          grunner.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </ExpandableDiv>

            {/* notes section */}
            <CRMNote id={id} />
          </>
        ) : (
          <div className={styles.headerContainer}>
            <ArrowBackIcon
              fontSize="large"
              style={{ marginLeft: "-5px", cursor: "pointer" }}
              onClick={handleGoBack}
            />
            <div className={styles.titleContainer}>
              <div className={styles.pageTitle}>
                No information is available
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default OrderDetails;
