/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styles from "./SearchBar.module.css";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ClipLoader from "react-spinners/ClipLoader";
import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";
import validator from "validator";

import { getSearchOptions } from "../../env/APIManager";

const SearchBar = ({
	searchString,
	onSearch,
	savedCategoryId,
	savedSearchQuery,
}) => {
	const [data, setData] = useState([]);
	const [selected, setSelected] = useState("");

	const [isOptionsOpen, setIsOptionsOpen] = useState(false);

	const [selectedCategoryId, setSelectedCategoryId] = useState("");
	const [inputValue, setInputValue] = useState(savedSearchQuery || "");
	const [loading, setLoading] = useState(false);

	const history = useHistory();

	const phoneUtil = PhoneNumberUtil.getInstance();

	useEffect(() => {
		let token =
			!localStorage.getItem("token")
				? ""
				: JSON.parse(localStorage.getItem("token"));

		// Create a variable to track whether the component is still mounted
		let isMounted = true;

		// Inside the asynchronous function, check if the component is still mounted before updating the state
		async function fetchData() {
			try {
				const response = await getSearchOptions(
					token,
					searchString?.toLowerCase()
				);
				if (!isMounted) {
					return; // If the component is unmounted, exit early
				}

				if (response?.code === "SUCCESS") {
					setData(response?.data);
					setLoading(false);

					// Check if savedCategoryId exists in the response data
					const savedCategory = response?.data.find(
						(option) => option.categoryId === savedCategoryId
					);

					if (savedCategory) {
						setSelected(savedCategory?.categoryName);
						setSelectedCategoryId(savedCategoryId);
					} else if (response?.data?.length > 0) {
						let defaultCategoryId = response?.data[0]?.categoryId;
						let defaultCategory = response?.data[0]?.categoryName;
						setSelected(defaultCategory);
						setSelectedCategoryId(defaultCategoryId);
					}
				} else if (response?.data?.code === "UNAUTHORIZED") {
					localStorage.clear();
					setLoading(false);
					toast.error(
						"You have been logged out. This may have been caused by using more than one device or browser"
					);
						history.push({ pathname: "/login" });
				} else {
					setLoading(false);
				}
			} catch (error) {
				console.error("API call error:", error);
				setLoading(false);
			}
		}

		if (searchString) {
			fetchData();
		} else {
			setData([]);
		}

		// Cleanup function to set isMounted to false when the component is unmounted
		return () => {
			isMounted = false;
		};
	}, [searchString]);

	const handleInputChange = (event) => {
		// const sanitizedValue = event.target.value.replace(/ +/g, "");
		const sanitizedValue = event.target.value
			.replace(/^\s+/, "")
			.replace(/ +/g, " ");

		setInputValue(sanitizedValue);
		if (
			sanitizedValue?.trim()?.length === 0 &&
			sanitizedValue !== savedSearchQuery
			// && selectedCategoryId !==savedCategoryId
		) {
			onSearch("", "");
		}
	};

	const handleClear = () => {
		setSelectedCategoryId("");
		setInputValue("");
		onSearch("", "");
	};

	const handleSubmit = () => {
		const selectedCategory = data?.find(
			(category) => category?.categoryId === selectedCategoryId
		);

		if (inputValue?.trim() === "") {
			toast.info("Please type in a value");
		} else if (selectedCategoryId?.trim() === "") {
			toast.info("Please type in a value");
		} else if (selectedCategory?.format === "phone") {
			const phoneNumberRegex = /^[+0-9 ()-]+$/;

			if (!phoneNumberRegex.test(inputValue)) {
				toast.error("Phone number needs to be in a valid format");

				return;
			}
			const parsedNumber =
				phoneUtil.parse(inputValue, "US") || phoneUtil.parse(inputValue, "CA");
			const isValidNumber = phoneUtil.isValidNumber(parsedNumber);

			if (isValidNumber) {
				const formattedPhoneNumber = phoneUtil.format(
					parsedNumber,
					PhoneNumberFormat.E164
				);
				onSearch(selectedCategoryId, formattedPhoneNumber);
				return;
			} else {
				toast.error(
					"Please verify that the number you have entered is valid and try again"
				);
				return;
			}
		} else if (selectedCategory?.format === "email") {
			if (!validator.isEmail(inputValue?.trim())) {
				toast.error("Email needs to be in a valid format");

				return;
			} else {
				onSearch(selectedCategoryId, inputValue);
				return;
			}
		} else if (selectedCategoryId && inputValue) {
			onSearch(selectedCategoryId, inputValue);
		}
	};

	const toggleOptions = () => {
		setIsOptionsOpen(!isOptionsOpen);
	};

	const handleSelection = (option) => {
		setSelected(option?.categoryName);
		setSelectedCategoryId(option?.categoryId);
		setIsOptionsOpen(false);
	};

	return (
		<>
			<ToastContainer limit={1}/>

			{loading ? (
				<div className={styles.clipLoader}>
					<ClipLoader color="#000" size={30} />
				</div>
			) : (
				<>
					{data?.length > 0 ? (
						<div className={styles.mainContainer}>
							<div className={styles.dropDownContainer}>
								<button
									type="button"
									className={styles.dropDownBtn}
									onClick={toggleOptions}
									aria-haspopup="listbox"
									aria-expanded={isOptionsOpen}
								>
									<div className={styles.btnContent}>
										<span className={styles.selected}>
											{selected ? selected : "Select"}{" "}
										</span>
										{isOptionsOpen ? (
											<KeyboardArrowUpIcon
												viewBox="0 0 23 23"
												fontSize="small"
											/>
										) : (
											<ExpandMoreIcon viewBox="0 0 23 23" fontSize="small" />
										)}
									</div>
								</button>
								{isOptionsOpen ? (
									<ul
										role="listbox"
										tabIndex={-1}
										className={styles.ulContainer}
									>
										{data?.map((option) => {
											return (
												<div
													className={
														selected === option?.categoryName
															? styles.selectedOption
															: styles.option
													}
													key={option?.categoryId}
													onClick={(e) => handleSelection(option)}
												>
													{option?.categoryName}
												</div>
											);
										})}
									</ul>
								) : null}
							</div>
							<input
								required
								type="text"
								placeholder="Enter value"
								value={inputValue}
								onChange={handleInputChange}
								className={styles.input}
							/>
							<div className={styles.btnContainer}>
								<button className={styles.btn} onClick={handleClear}>
									Clear
								</button>
								<button className={styles.btn} onClick={handleSubmit}>
									Search
								</button>
							</div>
						</div>
					) : (
						<div className={styles.mainContainerNoData}>
							<input
								className={styles.input}
								type="text"
								placeholder={`Search is not available for ${searchString}`}
								value={inputValue}
								onChange={handleInputChange}
							/>
							<div className={styles.btnContainer}>
								<button className={styles.btn} onClick={handleClear}>
									Clear
								</button>
								<button className={styles.btn} onClick={handleSubmit}>
									Search
								</button>
							</div>
						</div>
					)}
				</>
			)}
		</>
	);
};

export default SearchBar;
