/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ClipLoader from "react-spinners/ClipLoader";
import { useHistory } from "react-router-dom";

import { getConsumerUserDetails, updateUser } from "../../env/APIManager";
import styles from "./UserDetails.module.css";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import ArrowDropUpSharp from "@mui/icons-material/ArrowDropUpSharp";
import ModeEditSharpIcon from "@mui/icons-material/ModeEditSharp";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";

import UserOrders from "./UserOrders";
import CRMNote from "../crm-notes/crmNote";

const ExpandableDiv = ({ title, children }) => {
	const [isExpanded, setIsExpanded] = React.useState(false);

	const handleToggle = () => {
		setIsExpanded((prevState) => !prevState);
	};

	const showStyle = {
		height: "auto",
	};
	const hideStyle = {
		paddingTop: "4px",
		paddingBottom: "4px",
	};

	return (
		<div
			style={isExpanded ? showStyle : hideStyle}
			className={styles.collapseSection}
			key={title}
		>
			<div onClick={handleToggle} className={styles.mainTitleContainer}>
				{isExpanded ? <ArrowDropUpSharp /> : <ArrowDropDownSharpIcon />}
				<div className={styles.mainTitle}>{title}</div>
			</div>

			{isExpanded && <div className={styles.mainBodyContainer}>{children}</div>}
		</div>
	);
};
function UserDetails() {
	const [loading, setLoading] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);
	const [userDetails, setUserDetails] = useState({});
	const [tempUserDetails, setTempUserDetails] = useState({});

	const [openEditField, setOpenEditField] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [token, setToken] = useState("");

	const search = window.location.search;
	const params = new URLSearchParams(search);
	const id = params.get("id");
	const key = params.get("key");
	const nextPageToken = params.get("nextPageToken");

	const handleGoBack = () => {
		if (nextPageToken && key) {
			history.push(`/crm?key=${key}&nextPageToken=${nextPageToken}`);
		} else if (key && key !== undefined && key !== null) {
			history.push(`/crm?key=${key}`);
		} else {
			history.push(`/crm`);
		}
	};
	let history = useHistory();

	async function getUser(token, id) {
		setLoading(true);

		let data = await getConsumerUserDetails(token, id);

		if (data?.code === "SUCCESS") {
			setUserDetails(data?.data);
			setLoading(false);
		} else if (data?.data?.code === "UNAUTHORIZED") {
			localStorage.clear();
			setLoading(false);
			toast.error(
				"You have been logged out. This may have been caused by using more than one device or browser"
			);
			history.push({ pathname: "/login" });
		} else {
			toast.error("Something went wrong, please try again later");
			setLoading(false);
		}
	}

	useEffect(() => {
		let token =
			!localStorage.getItem("token")
				? ""
				: JSON.parse(localStorage.getItem("token"));
		setToken(token);

		if (!id) {
			if (nextPageToken && key) {
				history.push(`/crm?key=${key}&nextPageToken=${nextPageToken}`);
			} else {
				history.push(`/crm?key=${key}`);
			}
			return;
		}

		getUser(token, id);
	}, []);

	let patchUserKeys = {
		// User Info
		firstName: "firstName",
		lastName: "lastName",
		// GCash
		gCash: "gCash",
		gCashLimit: "gCashLimit",
	};
	let obj = {};

	const handleSave = async (field) => {
		if (
			tempUserDetails[field]?.length &&
			tempUserDetails[field]?.trim()?.length > 0 &&
			tempUserDetails[field]?.trim() !== userDetails[field]
		) {
			setShowModal(true);
		} else {
			toast.error("Please update the value to save");
			return;
		}
	};
	const handleConfirm = async (field) => {
		setShowModal(false);
		setBtnLoading(true);

		if (patchUserKeys[field]) {
			// Accumulate changes in obj
			obj[patchUserKeys[field]] = tempUserDetails[field];

			try {
				const updatedData = await updateUser(token, id, obj);

				if (updatedData?.code === "SUCCESS") {
					window.location.reload();
					setBtnLoading(false);
					closeEditMode();
				} else if (updatedData?.data?.code === "UNAUTHORIZED") {
					closeEditMode();
					localStorage.clear();
					setBtnLoading(false);
					toast.error(
						"You have been logged out. This may have been caused by using more than one device or browser"
					);
						history.push({ pathname: "/login" });
					} else {
					toast.error("Something went wrong, please try again later");
					setBtnLoading(false);
				}
			} catch (error) {
				console.error("Error:", error);
				toast.error("Something went wrong, please try again later", error);
				setBtnLoading(false);
			}
		}
	};

	const openEditMode = (field) => {
		setOpenEditField(field);
		setTempUserDetails((prevTempDetails) => ({
			...prevTempDetails,
			[field]: userDetails[field],
		}));
	};

	// Function to close edit mode for a field
	const closeEditMode = () => {
		setOpenEditField(null);
		setTempUserDetails({});
	};

	const renderEditableField = (fieldTitle, fieldName) => {
		const isNumericField = ["gCash", "gCashLimit"].includes(fieldName);

		return (
			<div className={styles.bodyContainer}>
				<div className={styles.bodyTitle}>{fieldTitle}</div>
				{openEditField === fieldName ? (
					<>
						<div className={styles.editField}>
							<input
								className={styles.input}
								value={
									tempUserDetails[fieldName] !== undefined
										? tempUserDetails[fieldName]
										: ""
								}
								onChange={(e) => {
									const inputValue = e.target.value;
									if (isNumericField) {
										const numericValue = inputValue.replace(/[^0-9]/g, "");

										if (
											numericValue.startsWith("0") &&
											numericValue.length > 1
										) {
											const sanitizedValue = numericValue.replace(/^0+/, "");
											setTempUserDetails((prevTempDetails) => ({
												...prevTempDetails,
												[fieldName]: sanitizedValue,
											}));
										} else {
											setTempUserDetails((prevTempDetails) => ({
												...prevTempDetails,
												[fieldName]: numericValue,
											}));
										}
									} else if (!/\d/.test(inputValue)) {
										// Check if inputValue contains any digit
										setTempUserDetails((prevTempDetails) => ({
											...prevTempDetails,
											[fieldName]: inputValue,
										}));
									}
								}}
								inputMode={isNumericField ? "numeric" : "text"}
							/>
							<div className={styles.actionBtnsContainer}>
								<button
									className={styles.actionBtn}
									onClick={() => handleSave(fieldName)}
								>
									Save
								</button>
								<button className={styles.actionBtn} onClick={closeEditMode}>
									Cancel
								</button>
							</div>
						</div>
						<Modal
							show={showModal}
							animation={true}
							style={{ marginTop: "40vh", paddingRight: "0" }}
						>
							<Modal.Body>
								<div
									style={{
										display: "flex",
										backgroundColor: "#fff",
										justifyContent: "center",
										alignItems: "center",
										flexDirection: "column",
										padding: "10px !important",
									}}
								>
									<div
										style={{
											fontFamily: "Mulish",
											fontSize: "18px",
											fontWeight: "700",
										}}
									>
										Are you sure you want to save the changes?
									</div>
								</div>
							</Modal.Body>

							<Modal.Footer
								style={{
									display: "flex",
									backgroundColor: "#fff",
									justifyContent: "center",
								}}
							>
								<button
									className={styles.cancelBtn}
									onClick={() => setShowModal(false)}
									disabled={btnLoading}
								>
									Cancel
								</button>

								<button
									className={styles.confirmBtn}
									onClick={() => handleConfirm(fieldName)}
									disabled={btnLoading}
								>
									{btnLoading ? (
										<div className={styles.btnClipLoader}>
											<ClipLoader color="#fff" size={20} />
										</div>
									) : (
										"Confirm"
									)}
								</button>
							</Modal.Footer>
						</Modal>
					</>
				) : (
					<div className={styles.editableField}>
						<div className={styles.field}>
							{userDetails[fieldName] !== undefined
								? userDetails[fieldName]
								: "N/A"}{" "}
						</div>

						<ModeEditSharpIcon
							fontSize="small"
							onClick={() => openEditMode(fieldName)}
						/>
					</div>
				)}
			</div>
		);
	};

	return (
		<>
			<ToastContainer limit={1} />
			<div className={styles.mainContainer}>
				{loading ? (
					<div className={styles.clipLoader}>
						<ClipLoader color="#000" size={50} />
					</div>
				) : userDetails && Object.keys(userDetails)?.length > 0 ? (
					<>
						<div className={styles.headerContainer}>
							<ArrowBackIcon
								fontSize="large"
								style={{ marginLeft: "-5px", cursor: "pointer" }}
								onClick={handleGoBack}
							/>
							<div className={styles.titleContainer}>
								<div className={styles.pageTitle}>
									User #{id?.substring(id?.length - 5)}{" "}
								</div>
							</div>
						</div>

						{/* user information section */}
						<ExpandableDiv title="User Information">
							<div className={styles.container}>
								<div className={styles.contentContainer}>
									<div className={styles.bodyContainer}>
										<div className={styles.bodyTitle}>Created</div>
										<div>
											{userDetails?.creationTime
												? new Date(
														userDetails?.creationTime
												  ).toLocaleDateString("en-US") +
												  " " +
												  new Date(
														userDetails?.creationTime
												  ).toLocaleTimeString([], {
														hour: "2-digit",
														minute: "2-digit",
												  })
												: "N/A"}
										</div>
									</div>

									<div className={styles.bodyInfoContainer}>
										{renderEditableField("First Name", "firstName")}
										{renderEditableField("Last Name", "lastName")}

										<div className={styles.bodyContainer}>
											<div className={styles.bodyTitle}>Phone Number</div>
											<div>
												{userDetails?.phoneNumber
													? userDetails?.phoneNumber
													: "N/A"}
											</div>
										</div>
										<div className={styles.bodyContainer}>
											<div className={styles.bodyTitle}>Email</div>
											<div>
												{userDetails?.email ? userDetails?.email : "N/A"}
											</div>
										</div>
									</div>

									<div className={styles.bodyInfoContainer}>
										<div className={styles.bodyContainer}>
											<div className={styles.bodyTitle}>Email Verified</div>
											<div>{userDetails?.emailVerified ? "Yes" : "No"}</div>
										</div>
									</div>
									<div className={styles.bodyInfoContainer}>
										<div className={styles.bodyContainer}>
											<div className={styles.bodyTitle}>Birthday</div>
											<div>
												{userDetails?.birthday ? userDetails?.birthday : "N/A"}
											</div>
										</div>
									</div>
								</div>
								<div className={styles.contentContainer}>
									<div className={styles.bodyContainer}>
										<div className={styles.bodyTitle}>
											Requested to deactivate the account
										</div>
										<div>{userDetails?.deactivated ? "yes" : "no"}</div>
									</div>
									<div className={styles.bodyContainer}>
										<div className={styles.bodyTitle}>
											Date requested to deactivate the account
										</div>
										<div>
											{userDetails?.deactivatedAt
												? new Date(
														userDetails?.deactivatedAt
												  ).toLocaleDateString("en-US") +
												  " " +
												  new Date(
														userDetails?.deactivatedAt
												  ).toLocaleTimeString([], {
														hour: "2-digit",
														minute: "2-digit",
												  })
												: "N/A"}
										</div>
									</div>

									<div className={styles.bodyInfoContainer}>
										<div className={styles.bodyContainer}>
											<div className={styles.bodyTitle}>Personal Coupon</div>
											<div>
												{userDetails?.personalCoupon
													? userDetails?.personalCoupon
													: "N/A"}
											</div>
										</div>

										{renderEditableField("gCash", "gCash")}
										{renderEditableField("gCash Limit", "gCashLimit")}

										<div className={styles.bodyContainer}>
											<div className={styles.bodyTitle}>CC on file</div>
											<div>
												{userDetails?.ccInfo ? userDetails?.ccInfo : "N/A"}
											</div>
										</div>
									</div>

									<div className={styles.bodyInfoContainer}>
										<div className={styles.bodyContainer}>
											<div className={styles.bodyTitle}>Voucherify Id </div>
											<div>
												{userDetails?.voucherifyId
													? userDetails?.voucherifyId
													: "N/A"}
											</div>
										</div>
									</div>
								</div>
							</div>
						</ExpandableDiv>
						<CRMNote id={id} />
						<UserOrders id={id} />
					</>
				) : (
					<div className={styles.headerContainer}>
						<ArrowBackIcon
							fontSize="large"
							style={{ marginLeft: "-5px", cursor: "pointer" }}
							onClick={handleGoBack}
						/>
						<div className={styles.titleContainer}>
							<div className={styles.pageTitle}>
								No information is available
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
}

export default UserDetails;
